import { CHANGE_LANGUAGE, CHANGE_LANGUAGUE, CHANGE_NOTIFICATIONS, CHANGE_RECEIVING_ORDER_STATUS, CHANGE_TOKEN, CHANGE_USER_DATA } from "./GlobalActionsTypes"

const initialState = {
    lang:'en',
    notifications:{},
    token:localStorage.getItem('token')?localStorage.getItem('token'):null,
    userData:null,
    receivingOrdersStatus:localStorage.getItem('receivingOrdersStatus')?localStorage.getItem('receivingOrdersStatus'):1,
}
// receiving_orders_status  => 1 => receiving
// receiving_orders_status  => 2 =>  not receiving
const GlobalReducer = (state = initialState ,action:any)=>{
    switch (action.type){
        case CHANGE_LANGUAGE :{
            return {
                ...state,
                lang:action.lang
            }
        }
        case CHANGE_TOKEN :{
            return {
                ...state,
                token:action.token
            }
        }
        case CHANGE_USER_DATA :{
            return {
                ...state,
                userData:action.userData
            }
        }
        case CHANGE_LANGUAGUE :{
            return {
                ...state,
                lang:action.code
            }
        }
        case CHANGE_NOTIFICATIONS :{
            return {
                ...state,
                notifications:action.notifications
            }
        }
        case CHANGE_RECEIVING_ORDER_STATUS :{
            return {
                ...state,
                receivingOrdersStatus:action.receivingOrdersStatus
            }
        }
        default: return state;
    }
}

export default GlobalReducer