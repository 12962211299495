import {useEffect} from 'react'
import { onMessageLisener, requestPermission } from './FirebaseConfig.js'
// import { requestPermission } from './FirebaseConfig'

const Notification = () => {
  useEffect(()=>{
    requestPermission()
    const unsubscribe = onMessageLisener().then((payload:any)=>{
      console.log('payload',payload)
    })
    return ()=>{
      unsubscribe.catch((err:any)=>console.log(err))
    }
  },[])
  
  return (
    <div>
      {/* not */}
    </div>
  )
}

export {Notification}
